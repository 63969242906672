.Reasons{
    padding: 0.2rem;
    display: flex;
    gap: 2rem;
}
.left-r{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}
.left-r{
    object-fit: cover;
}
.left-r>:nth-child(1){
   width: 12rem;
   grid-row: 1/3;
   height: 20rem;
}
.left-r>:nth-child(2){
    width: auto;
    height: 16rem;
    grid-column: 2/4;
 }
 .left-r>:nth-child(3){
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;
 }
 .left-r>:nth-child(4){
   width: 10rem;
   grid-row: 2;
   grid-column: 3/4;
   height: 11.4rem;
 }
.right-r{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
.right-r>span{
    font-weight: bold;
    color: var(--orange);
}
.right-r>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.details-r>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}
.details-r>div>img{
    width: 2rem;
    height: 2rem;
}
.partners{
    display: flex;
    gap: 1rem;
}
.partners>img{
    width: 2rem;
}
@media screen and (max-width: 768px)
{
.Reasons{
    flex-direction: column;
}
.left-r{
    grid-auto-rows: auto;
    overflow: hidden;
}
.left-r>:nth-child(1){
    width: 7rem;
    height: 17rem;
}
.left-r>:nth-child(2){
    width: 15rem;
    height: 10rem;
}
.left-r>:nth-child(3){
    width: 7rem;
}
.left-r>:nth-child(4){
    width: 7rem;
    height: 5.59rem;
}
}